import React, { useState, useEffect, useRef } from 'react';
import axios from '../../services/apiService';
import { store } from '../../store';
import {
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CCol,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
} from '@coreui/react';
import backgroundImage from '../../assets/background_smoking.jpg';
import { useSearchParams } from "react-router-dom";
import Login from "../views/login/login";
import { Helmet } from 'react-helmet';

export const Intrebari = ({loggedIn, setLoggedIn}) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [authToken, setAuthToken] = useState(store?.getState().authTokenVanator);
  const [intrebari, setIntrebari] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [capitol, setCapitol] = useState(searchParams.get("capitol") || 1);
  const [subCapitol, setSubCapitol] = useState(searchParams.get("subCapitol") || 'A');
  const [modInvatare, setModInvatare] = useState(false);
  const capitole = [
    'Capitol 1 - Biologia Speciilor de faună cinegetică',
    'Capitol 2 - Legislație în domeniul cinegetic', 
    'Capitol 3 - Etică vânătorească', 
    'Capitol 4 - Chinologie',
    'Capitol 5 - Boli ale vânatului',
    'Capitol 6 - Arme, Muniții și echipamente de vânătoare',
    'Capitol 7 - Managementul Speciilor de interes cinegetic',
    'Capitol 8 - Organizarea si practicarea vânătorii',
    '- Toate Întrebările -'
  ];
  const subCapitole = {
    'A': 'Mamifere mari',
    'B': 'Mamifere mici',
    'C': 'Păsări',
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [user, setUser] = useState(store?.getState().user);
  const [page, setPage] = useState(1);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isPageFinal, setIsPageFinal] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const handleScroll = () => {
    setShowScrollButton(window.scrollY > window.innerHeight);
  };

  const toggleModal = () => {
    setSignUp(false);
    setAuthToken(store?.getState().authTokenVanator);
    setUser(store?.getState().user);
    setIsModalVisible(!isModalVisible);
    setLoggedIn(true);
    setModInvatare(true);
  };

  const startMediuInvatare = () => {
    if (store?.getState().authTokenVanator && modInvatare) {
      setModInvatare(false);
    }
    else if(store?.getState().authTokenVanator && !modInvatare) {
      setModInvatare(true);
      window.scrollTo({ top: 0, behavior: 'smooth' })
      const shuffledData = [...intrebari];
      for (let i = shuffledData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledData[i], shuffledData[j]] = [shuffledData[j], shuffledData[i]];
      }
      setIntrebari(shuffledData);
    }
    else {
      setModInvatare(false);
      setIsModalVisible(true);
    }
    
  }

  const setHeight = () => {
    const navbar = document.querySelector('nav');
    const stickyDiv= document.querySelector('.chapter-selector');

    if (navbar && stickyDiv) {
      const navbarHeight = navbar.offsetHeight;
      stickyDiv.style.top = navbarHeight + 'px';
    }

    const content = document.querySelector('.content');

    if (stickyDiv && content) {
      const stickyDivHeight = stickyDiv.offsetHeight + 10;
      content.style.top = stickyDivHeight + 'px';
    }
  }

  useEffect(() => {
    if (!searchParams.get('capitol')) {
      setSearchParams({capitol: 1, subCapitol: 'A'})
    }
    window.addEventListener('scroll', handleScroll);
    setHeight();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const fetchData = async (append, pagina) => {
    try {
      let apiUrl;
      if (capitol > 1) {
        apiUrl = `${process.env.REACT_APP_API_URL}/intrebari/?capitol=${capitol}&page=${pagina}`;
      } else {
        apiUrl = `${process.env.REACT_APP_API_URL}/intrebari/?capitol=${capitol}&subcapitol=${subCapitol}&page=${pagina}`;
      }
      const response = await axios.get(apiUrl);
      const newData = response.data.questions;
      if (newData.length < 30) {
        setIsPageFinal(true);
      }
      if (append) {
        setIntrebari([...intrebari, ...newData]);
      }
      else {
        setIntrebari(newData);
      }
      
    } catch (error) {
    }
  };

  const getNextPage = () => {
    setPage(page + 1);
    fetchData(true, page + 1)
  }

  useEffect(() => {
    setPage(1);
    fetchData(false, 1);
    setIsPageFinal(false);
    window.scrollTo(0, 0);
    setSelectedAnswers([]);
  }, [capitol, subCapitol]);


  const handleAnswerSelect = (questionIndex, answerIndex) => {
    axios.post(`${process.env.REACT_APP_API_URL}/user/intrebare-rezolvata`, {
      questionId: intrebari[questionIndex]._id,
      capitol: capitol
    })
      .then(response => {
      })
      .catch(error => {
      })
    const updatedSelectedAnswers = [...selectedAnswers];
    updatedSelectedAnswers[questionIndex] = answerIndex;
    setSelectedAnswers(updatedSelectedAnswers);
  };

  return (
    <div className='custom-background' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Helmet>
        <title>Ghidul Întrebărilor de Examen pentru Vânători | ExamenVanator</title>
        <meta name="description" content="Descoperă toate întrebările examenului de vânător, structurate pe capitole, pentru a te pregăti eficient pentru examenul de vânătoare. Ghidul complet al întrebărilor de examen de la ExamenVanator." />
        <meta name="keywords" content="fauna cinegetică, România, examen de vânătoare, vânătoare, vanator, test, capitole" />
        <meta property="og:title" content="Ghidul Întrebărilor de Examen pentru Vânători | ExamenVanator" />
        <meta property="og:description" content="Descoperă toate întrebările examenului de vânător, structurate pe capitole, pentru a te pregăti eficient pentru examenul de vânătoare. Ghidul complet al întrebărilor de examen de la ExamenVanator." />
        <meta name="twitter:title" content="Ghidul Întrebărilor de Examen pentru Vânători | ExamenVanator" />
        <meta name="twitter:description" content="Descoperă toate întrebările examenului de vânător, structurate pe capitole, pentru a te pregăti eficient pentru examenul de vânătoare. Ghidul complet al întrebărilor de examen de la ExamenVanator." />
        <link rel="canonical" href="https://examenvanator.com/intrebari" />
        <meta name="google-adsense-account" content="ca-pub-2433672990215318"></meta>
      </Helmet>
      <CModal visible={isModalVisible} onClose={() => setIsModalVisible(false)}>
      <CModalHeader closeButton>
        <CModalTitle>{signUp ? "Conectare" : "Creare cont" }</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <Login someProp = {signUp} resetPassword = {resetPassword} closeModal={() => toggleModal()}/>
        <CModalFooter>
        {!resetPassword ? 
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <div style={{marginRight: '10px'}}>{signUp ? 'Nu ai cont? ' : 'Ai deja cont? '} </div>
              <button className='btn-ok' onClick={() => setSignUp(!signUp)} style={{ background: '#163020' }}>{signUp ? 'Creează unul acum' : 'Autentifică-te.'}</button>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '10px'
            }}>
            {signUp ? 
              <> 
                <div style={{marginRight: '10px'}} >Ai uitat parola?</div>
                <button className='btn-ok' onClick={() => setResetPassword(!resetPassword)} style={{ background: '#163020' }}>Resetare parolă</button> 
              </> : 
              <></> 
            }
            </div>
          </div>
        : <button className='btn-ok' onClick={() => setResetPassword(!resetPassword)} style={{ background: '#163020' }}>Înapoi spre autentificare</button> }
        </CModalFooter>
      </CModalBody>
    </CModal>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <CTable style={{ width: '80%', margin: 'auto'}}>
        <CTableHead className='chapter-selector'>
          <CTableRow>
            <CTableHeaderCell>
              <div className="selector-container">
                <div className="selector">
                  <CFormSelect
                    id="sortField"
                    onChange={(e) => {
                      setCapitol(e.target.value);
                      setSearchParams({ capitol: e.target.value });
                    }}
                    value={capitol}
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      fontWeight: 'bold',
                      whiteSpace: 'pre',
                    }}
                  >
                    {capitole.map((option, index) => (
                      <option key={index + 1} value={index + 1}>
                        {option}
                      </option>
                    ))}
                  </CFormSelect>
                </div>
                {capitol && capitol == 1 && (
                  <div className="selector">
                    <CFormSelect
                      id="sortSubcapitol"
                      onChange={(e) => {
                        setSubCapitol(e.target.value);
                        setSearchParams({ capitol: capitol, subCapitol: e.target.value });
                      }}
                      value={subCapitol}
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        fontWeight: 'bold',
                        whiteSpace: 'pre',
                      }}
                    >
                      {Object.entries(subCapitole).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>
                )}
                <button
        onClick={startMediuInvatare}
        style={{
          // display: showScrollButton ? 'block' : 'none',
          // position: 'fixed',
          // bottom: '50px',
          // right: '15px',
        }}
        className='btn-ok'
      >
       {modInvatare  ? 'Start mediu învățare' : 'Start mediu verificare'}
      </button>
              </div>
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody style={{ height: '400px', overflowY: 'auto'}}>
          {intrebari.map((question, index) => (
            <CTableRow key={question._id}>
              <CTableDataCell>
                <p>{question.question}</p>
                {question.image && (
                  <img src={question.imageUrl} alt={`Imagine pentru întrebare ${index + 1}`} />
                )}
                 <div>
                  {question.answers.map((answer, answerIndex) => (
                    <div
                      key={answer._id}
                      style={{
                        listStyleType: 'none',
                        background:
                          answer.isCorrect && !modInvatare ? 'rgba(0, 128, 0, 0.6)'
                          : !answer.isCorrect && modInvatare && selectedAnswers[index] == answerIndex ? 'rgba(255, 99, 71, 1)'
                          : answer.isCorrect && modInvatare && selectedAnswers[index] != null ? 'rgba(0, 128, 0, 0.6)' 
                          : ''
                      }}
                      className={modInvatare && selectedAnswers[index] == null ? 'answer-list-item' : ''}
                    >
                      <label>
                      {modInvatare && selectedAnswers[index] == null  ? (
                          <input
                            type="radio"
                            name={`question${index + 1}`}
                            onChange={() => handleAnswerSelect(index, answerIndex)}
                          />
                        ) : null}
                        {answer.text}
                        {answer.imageUrl && (
                          <img className='imagine-raspuns' src={answer.imageUrl} alt={`Imagine pentru răspunsul ${answer.text}`} />
                        )}
                      </label>
                    </div>
                  ))}
                </div>
              </CTableDataCell>
            </CTableRow>
          ))}
          <CTableRow>
            {isPageFinal ? 
              capitol > 1 ?
              <CTableDataCell>{`${capitole[capitol - 1]}`}</CTableDataCell> : 
              <CTableDataCell>{`${capitole[capitol - 1]} - ${subCapitole[subCapitol]} `}</CTableDataCell>
              : 
              <CTableDataCell>  
                <button onClick={getNextPage} className='btn-ok'>Incarca mai multe</button>
              </CTableDataCell>  
            } 
          </CTableRow>
        </CTableBody>
      </CTable>
    </div>
    </div>
  );
};

