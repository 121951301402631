import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CInputGroup,
} from '@coreui/react'
import axios from '../../../services/apiService';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';


const Login = ({someProp, resetPassword, closeModal, forgotPasswordToken }) => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [repeatPassword, setRepeatPassword] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] =useState(null);
  const [passwordsMatchError, setPasswordsMatchError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [signUpError, setSignUpError] = useState(null);
  const [resetOk, setResetOk] = useState(null);

  const manageSuccessLoginGoogle = (credentialResponse) => {
      axios.post(`${process.env.REACT_APP_API_URL}/auth/google`, {
          googleToken: credentialResponse.credential,
      }).then(response => {
          if (response.data.token) {
              dispatch({
                  type: 'set',
                  authTokenVanator: response.data.token,
              });
              dispatch({
                  type: 'set',
                  user: response.data.data,
              });
              closeModal && closeModal();                                     
            }
      })
      .catch(error => {
          
      })
  }

  const validateEmail = (email) => {
      if (!email) return false;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
      event.preventDefault();

      if (!someProp && password !== repeatPassword) {  
          setPasswordsMatchError("Parolele nu sunt aceleași");
          setEmailError(null);
          return;
      }

      if (!validateEmail(email)) {
          setEmailError("Vă rugăm să introduceți o adresă de email validă");
          setPasswordsMatchError(null);
          return;
      }

      // Reset the error message
      setPasswordsMatchError(null);
      setEmailError(null);
      if (email && password && firstName && lastName) {
          axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
              email: email,
              password: password,
              firstName: firstName,
              lastName: lastName,
          })
          .then(response => {
              if (response.data.token) {
                  // set authToken in store
                  dispatch({
                      type: 'set',
                      authTokenVanator: response.data.token
                    });    
                  closeModal && closeModal();                                       
                }
          })
          .catch(error => {
            if (error?.response.data.statusCode === 400) {
                setSignUpError(error?.response.data.message)
            }
          })
      }
      else if (email && password) {
          axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
              email: email,
              password: password,
          })
          .then(response => {
              if (response.data.token) {
                  dispatch({
                      type: 'set',
                      authTokenVanator: response.data.token
                    });   
                  closeModal && closeModal();
                }
          })
          .catch(error => {
              if (error?.response.data.statusCode === 400) {
                  setSignUpError(error?.response.data.message)
              }
          })
      }

      setValidated(true); // Set validation state to true
  }

  const handleSubmitForgot  = (event) => {
    event.preventDefault();

    if (password !== repeatPassword) {
      setPasswordsMatchError("Parolele nu sunt aceleași");
      return;
    }

    axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password-change`,{
      forgotPasswordToken: forgotPasswordToken,
      password: password,
    })
    .then(response => {
      setPasswordsMatchError(null);
      setResetOk('Parola a fost schimbată cu succes. Vă rugăm să vă autentificați cu noua parolă.');
    })
    .catch(error => {
    })
  }

  const handleSubmitReset = (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setEmailError("Vă rugăm să introduceți o adresă de email validă");
      setPasswordsMatchError(null);
      return;
    }
    axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`,{
      email: email
    })
    .then(response => {
      setEmailError(null);
      setResetOk('Dacă există un cont asociat cu această adresa de email, veți primi un email de resetare a parolei');
    })
    .catch(error => {
        
    })
  }

  useEffect(() => {
    setEmailError(null);
    setPasswordsMatchError(null);
  }, [someProp, resetPassword])

  return (
    <div className="d-flex flex-column justify-content-center align-items-center p-4">
      {!resetPassword && !forgotPasswordToken ? 
        <>
          <CForm
            className="row g-3 needs-validation"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
      <CInputGroup className="mb-3">
            <CFormInput onChange={(event) => setEmail(event.target.value)} placeholder="Email" autoComplete="email" />
      </CInputGroup>
        {emailError && (
        <div className="text-danger">{emailError}</div>
        )}
        <CInputGroup className="mb-3">
          <CFormInput
            onChange={(event) => setPassword(event.target.value)}
            type="password"
            placeholder="Parolă"
            autoComplete="new-password"
            required
          />
          </CInputGroup>
          {!someProp && (
            <>
              <CInputGroup className="mb-3">
                <CFormInput
                  onChange={(event) => setRepeatPassword(event.target.value)}
                  type="password"
                  placeholder="Repetă Parola"
                  autoComplete="new-password"
                  required
                />
              </CInputGroup>
                {passwordsMatchError && (
                  <div className="text-danger">{passwordsMatchError}</div>
                )}
              <CCol sm={12} lg={6}>
                  <CFormInput
                    onChange={(event) => setFirstName(event.target.value)}
                    placeholder="Prenume"
                    autoComplete="given-name"
                    required
                    className="mb-3"
                  />
              </CCol>
              <CCol sm={12} lg={6}>
                <CFormInput
                  onChange={(event) => setLastName(event.target.value)}
                  placeholder="Nume"
                  autoComplete="family-name"
                  required
                  className="mb-3"
                />
              </CCol>
            </>
          )}
          <GoogleLogin
              onSuccess={credentialResponse => {
                manageSuccessLoginGoogle(credentialResponse);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
          />
          <CButton type="submit" style={{ background: '#163020' }}>{someProp ? 'Conectează-te' : 'Creează cont'}</CButton>
          {signUpError ? <div className="text-danger">{signUpError}</div> : <></>}
      </CForm>
        </> : !forgotPasswordToken && resetPassword ? 
        <>
        <CForm
            className="row g-3 needs-validation"
            noValidate
            validated={validated}
            onSubmit={handleSubmitReset}
          >
            <CFormInput
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Adresă email"
              autoComplete="emailAddress"
              required
              className="mb-3"
            />
            {emailError && (
              <div className="text-danger">{emailError}</div>
            )}
            <button type="submit" className="btn-ok">Resetează parola</button>
            {resetOk && (
              <div>{resetOk}</div>
            )}
          </CForm>
        </> : forgotPasswordToken ? 
        <>
          <CForm
            className="row g-3 needs-validation"
            noValidate
            validated={validated}
            onSubmit={handleSubmitForgot}
          >
            <CFormInput
              onChange={(event) => setPassword(event.target.value)}
              type="password"
              placeholder="Parolă"
              autoComplete="new-password"
              required
            />
            <CFormInput
              onChange={(event) => setRepeatPassword(event.target.value)}
              type="password"
              placeholder="Repetă Parola"
              autoComplete="new-password"
              required
            />
            <button type="submit" className="btn-ok">Resetează parola</button>
            {passwordsMatchError && (
                  <div className="text-danger">{passwordsMatchError}</div>
                )}
            {resetOk && (
              <div>{resetOk}</div>
            )}
          </CForm>
        </> : <></>
      }
      
    </div>
  );
};

export default Login;
