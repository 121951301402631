import backgroundImage from '../../assets/background_smoking.jpg';
import { Helmet } from 'react-helmet';
export const Home = () => {
  
  return (
    <div className='custom-background-home' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Helmet>
        <title>Ghidul Faunei Cinegetice din România | ExamenVanator</title>
        <meta name="description" content="Explorează diversitatea faunei cinegetice din România prin intermediul examenelor de vânătoare oferite de ExamenVanator. Alătură-te comunității noastre de vânători pasionați și descoperă bogăția naturii românești." />
        <meta name="keywords" content="fauna cinegetică, România, examen de vânătoare, vânătoare, vanator, test cinegetic" />
        <meta property="og:title" content="Ghidul Faunei Cinegetice din România | ExamenVanator" />
        <meta property="og:description" content="Explorează diversitatea faunei cinegetice din România prin intermediul examenelor de vânătoare oferite de ExamenVanator. Alătură-te comunității noastre de vânători pasionați și descoperă bogăția naturii românești." />
        <meta name="twitter:title" content="Ghidul Faunei Cinegetice din România | ExamenVanator" />
        <meta name="twitter:description" content="Explorează diversitatea faunei cinegetice din România prin intermediul examenelor de vânătoare oferite de ExamenVanator. Alătură-te comunității noastre de vânători pasionați și descoperă bogăția naturii românești." />
        <meta name="google-adsense-account" content="ca-pub-2433672990215318"></meta>
      </Helmet>
      <div className="introduction">
        <h1>Bine ați venit pe site-ul nostru de pregătire pentru examenul de vânător!</h1>
        <p>
          Acesta este locul unde veți găsi toate resursele necesare pentru a vă pregăti în mod eficient și complet pentru examenul de vânător.
          Întrebările prezentate aici sunt cele oficiale, oferite de Ministerul Mediului, Apelor și Pădurilor.
        </p>
        <p>
          Acestea pot fi descărcate accesând <a href='https://mmediu.ro/app/webroot/uploads/files/Set%20de%20%C3%AEntreb%C4%83ri%20%C8%99i%20r%C4%83spunsuri%20privind%20examenul%20de%20v%C3%A2n%C4%83tor%20actualizate%20conform%20Legii%20nr.%20171%20din%202022.doc'>site-ul oficial al Ministerul Mediului, Apelor și Pădurilor</a>
        </p>
        <p>
          Scopul nostru este de a vă ajuta să vă familiarizați cu materialele de studiu, să înțelegeți conceptele esențiale 
          și să vă exersați cunoștințele prin intermediul întrebărilor de test.
          Ne dorim ca fiecare viitor vânător care vizitează acest site să se simtă mai încrezător și mai bine pregătit pentru examenul ce îl privește.
        </p>
        <h2>Exemplare</h2>
        <p>
          Secțiunea de Exemplare ilustrează toate speciile Faunei Cinegetice prezente în Romania împreună cu perioada aferentă de vânătoare.
          Prin selectarea unei specii de vănat, sistemul nostru va încerca sa găsească întrebări direct conectate cu specia aleasă.
          Fiind la început, sunt câteva specii pentru care acesta poate detecta eronat. 
        </p>
        <h2>Mediu de Învățare</h2>
        <p>
          În secțiunea Mediu de Învățare puteti parcurge toate întrebările prezentate mai sus. Pentru o mai ușoara înțelegere, acestea au fost structurate pe capitole,
          exact asa cum sunt prezentate de catre Ministerul Mediului, Apelor și Pădurilor.
        </p>
        <p>
          Prin accesarea mediului de verificare disponibil în cadrul paginii prezentate anterior, vă puteți verifica rapid cunoștintele. 
          Aceasta opțiune este disponibile doar utilizatorilor autentificați deoarece sistemul nostru reține întrebarile deja parcurse si nu va afișeaza spre verificare
          o întrebare deja parcursă.
        </p>
        <p>
          Progresul privind fiecare capitol poate fi urmărit în pagina de profil, pagină disponibilă spre accesare după autentificare.
          De asemenea acesta poate fi resetat astfel încât utilizatorul sa poată relua spre verificare întrebările deja parcurse.
        </p>
        <h2>Test Oficial</h2>
        <p>
          Aceasta secțiune oferă spre testarea cunoștințelor un test sub formatul oficial, 30 de întrebări din materie, la final oferind punctajul obținut.
          Pentru utilizatorii conectați, fiecare test realizat se va salva si informații legate de progresul fiecăruia vor fi disponibile in pagina de profil
          (numărul de teste realizate, promovate, procentul de teste promovate, etc).
          Pentru a promova un test este necesar să fie rezolvate corect 20 din cele 30 de întrebări oferite.
        </p>
        <p>
          Vă invităm să explorați conținutul site-ului, să răspundeți la întrebări și să vă pregătiți pentru succesul în cariera de vânător.
          Mult succes!
        </p>
        <p>
          Pentru orice nelămurire, va stăm la dispoziție prin <a href="mailto:examenvanator@gmail.com">email</a>.
        </p>
      </div>
    </div>
    );
};