import React, { useState, useEffect } from 'react';
import axios from '../../services/apiService';
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableRow,
} from '@coreui/react';
import backgroundImage from '../../assets/background_smoking.jpg';
import { Helmet } from 'react-helmet';

export const Test = () => {
  const [selectedAnswers, setSelectedAnswers] = useState(Array(30).fill(null));
  const [test, setTest] = useState([]);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [correctCount, setCorrectCount] = useState(null);

  const calculateCorrectAnswers = () => {
    let correctCount = 0;
    test.forEach((question, index) => {
      const correctAnswerIndex = question.answers.findIndex((answer) => answer.isCorrect);
      const selectedAnswerIndex = selectedAnswers[index];
      if (correctAnswerIndex !== -1 && selectedAnswerIndex !== undefined && selectedAnswerIndex === correctAnswerIndex) {
        correctCount++;
      }
    });
    return correctCount;
  };

  const handleFinalizeQuiz = () => {
    setQuizCompleted(true);
    const correctCount = calculateCorrectAnswers();
    setCorrectCount(correctCount);
    axios.post(`${process.env.REACT_APP_API_URL}/user/test-finalizat`, {
      punctaj: correctCount,
    })
    .catch(error => {
      console.log(error);
    })
    window.scrollTo({ top: 0, behavior: 'smooth' })

    axios.post(`${process.env.REACT_APP_API_URL}/intrebari/test`, {correctAnswers: correctCount})
    .catch(error => {
      console.log(error);
    })
  };

  // const [showScrollButton, setShowScrollButton] = useState(false);

  // const handleScroll = () => {
  //   setShowScrollButton(window.scrollY > window.innerHeight);
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const setHeight = () => {
    const navbar = document.querySelector('nav');
    const stickyDiv= document.querySelector('.chapter-selector');

    if (navbar && stickyDiv) {
      const navbarHeight = navbar.offsetHeight;
      stickyDiv.style.top = navbarHeight + 'px';
    }

    const content = document.querySelector('.content');

    if (stickyDiv && content) {
      const stickyDivHeight = stickyDiv.offsetHeight + 10;
      content.style.top = stickyDivHeight + 'px';
    }
  }

  const getTest = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/intrebari/test`)
    .then(response => {
      setTest(response.data.questions);
      setCorrectCount(null);
      setQuizCompleted(false);
    })
    .catch(error => {
    });
  }

  useEffect(() => {
    getTest();
    setHeight();
  }, []);

  const handleAnswerSelect = (questionId, answerIndex) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId - 1]: answerIndex,
    }));
  };

  return (
    <div className='custom-background' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Helmet>
        <title>Test Oficial pentru Examenul de Vânător - 30 de Întrebări Esențiale | ExamenVanator</title>
        <meta name="description" content="Pregătește-te pentru examenul de vânător cu testul oficial de 30 de întrebări esențiale oferit de ExamenVanator. Acest instrument este crucial pentru succesul tău în obținerea permisului de vânătoare." />
        <meta name="keywords" content="fauna cinegetică, România, examen de vânătoare, examen vanator, vânătoare, vanator, test oficial vanator, test vanatoare, capitole, întrebări esențiale" />
        <meta property="og:title" content="Test Oficial pentru Examenul de Vânător - 30 de Întrebări Esențiale | ExamenVanator" />
        <meta property="og:description" content="Pregătește-te pentru examenul de vânător cu testul oficial de 30 de întrebări esențiale oferit de ExamenVanator. Acest instrument este crucial pentru succesul tău în obținerea licenței de vânătoare." />
        <meta name="twitter:title" content="Test Oficial pentru Examenul de Vânător - 30 de Întrebări Esențiale | ExamenVanator" />
        <meta name="twitter:description" content="Pregătește-te pentru examenul de vânător cu testul oficial de 30 de întrebări esențiale oferit de ExamenVanator. Acest instrument este crucial pentru succesul tău în obținerea licenței de vânătoare." />
        <link rel="canonical" href="https://examenvanator.com/test" />
        <meta name="google-adsense-account" content="ca-pub-2433672990215318"></meta>
      </Helmet>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <CTable style={{ width: '80%', margin: 'auto'}}>
          <CTableHead className='chapter-selector'>
            <CTableRow>
                {correctCount !== null ? (
                    <CTableDataCell style={{fontWeight: 'bold', color: correctCount >= 20 ? 'rgba(0, 128, 0, 0.6)' : 'rgba(255, 0, 0, 0.6)' }}>
                    Total: {correctCount}/30 ({Math.round((correctCount / 30) * 100)}%)
                    </CTableDataCell>
                ) : (
                    <CTableDataCell
                      style={{fontWeight: 'bold', border: 'none' }}
                    >
                      Test Oficial 30 de întrebări
                    </CTableDataCell>
                )}
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {test.map((question, index) => (
              <CTableRow key={question._id}>
                <CTableDataCell>
                    <div>
                        <p>{index + 1}. {question.question}</p>
                        {question.image && (
                            <img src={question.imageUrl} alt={`Imagine pentru întrebare ${index + 1}`} />
                        )}
                        <ul>
                            {question.answers.map((answer, answerIndex) => (
                            <li
                                key={answer._id}
                                style={{
                                background:
                                    quizCompleted &&
                                    (answer.isCorrect
                                    ? 'rgba(0, 128, 0, 0.6)' // Correct answer color (green)
                                    : selectedAnswers[index] === answerIndex
                                    ? 'rgba(255, 0, 0, 0.6)' // Wrong selected answer color (red)
                                    : ''),
                                }}
                                className="answer-list-item"
                            >
                                <label style={{ display: 'block', padding: '5px' }}>
                                <input
                                    type="checkBox"
                                    name={`question${index + 1}`}
                                    checked={selectedAnswers[index] === answerIndex}
                                    onChange={() => handleAnswerSelect(index + 1, answerIndex)}
                                    disabled={quizCompleted}
                                />
                                {answer.text}
                                {answer.imageUrl && (
                                    <img className='imagine-raspuns' src={answer.imageUrl} alt={`Imagine pentru răspunsul ${answer.text}`} />
                                )}
                                </label>
                            </li>
                            ))}
                        </ul>
                    </div>
                </CTableDataCell>
              </CTableRow>
            ))}
            <CTableRow>
              <CTableDataCell>   
                <button onClick={handleFinalizeQuiz} className='btn-ok'>Finalizează</button>
              </CTableDataCell>
            </CTableRow>
          </CTableBody>
        </CTable>
        <button
          onClick={() => {getTest(); window.scrollTo({ top: 0, behavior: 'smooth' });}}
          style={{
            display: correctCount !== null ? 'block' : 'none',
            position: 'fixed',
            bottom: '20px',
            right: '20px',
          }}
          className='btn-ok'
        >
          Începe un test nou
        </button>
      </div>
    </div>
  );
};

