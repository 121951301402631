import { HashRouter, Route, Routes, Redirect, BrowserRouter } from "react-router-dom";
// import "./App.css";
import { Navbar } from "./components/Navbar";
import { Intrebari, Contact, Test, Profil, Home, Exemplare, Exemplar } from "./components/pages";
import Login from "./components/pages/Login";
import DefaultLayout from "./components/layout/DefaultLayout";
import './scss/style.scss'
import React, { useState, useEffect } from 'react';
import { store } from "./store";
import Privacy from "./components/pages/Privacy";
import TermsOfService from "./components/pages/TermsOfService";

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar props={store?.getState().authTokenVanator} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>
        <Routes>
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/exemplare" element={<Exemplare />} />
          <Route path="/intrebari" element={<Intrebari loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
          <Route path="/login" element={<Login />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/test" element={<Test />}/>
          <Route path="/profil" element={<Profil />}/>
          <Route path="/home" element={<Home />}/>
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
