import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton
} from '@coreui/react';
import "./Navbar.css";
import Login from "./views/login/login";
import { useDispatch } from 'react-redux';
import {store} from '../store';
import { useSearchParams } from "react-router-dom";

export const Navbar = ({props, loggedIn, setLoggedIn}) => {
  const dispatch = useDispatch();
  const [authToken, setAuthToken] = useState(store?.getState().authTokenVanator);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [user, setUser] = useState(store?.getState().user);
  const [searchParams, setSearchParams] = useSearchParams();
  const forgotPasswordToken = searchParams.get("token");

  useEffect(() => {
    setUser(store?.getState().user);
    setAuthToken(store?.getState().authTokenVanator);
  }, [loggedIn])

  const toggleModal = () => {
    setSignIn(false);

    setAuthToken(store?.getState().authTokenVanator);
    setUser(store?.getState().user);
    setIsModalVisible(false);

    if (searchParams.has('token')) {
      searchParams.delete('token');
      setSearchParams(searchParams);
    }
  };

  const logout = () => {
    dispatch({ type: 'set', authTokenVanator: null });
    dispatch({ type: 'set', user: null });
    setAuthToken(null);
    setUser(null);
    setConfirmLogout(false);
    setLoggedIn(false);
  };

  const toggleModalSignIn = () => {
    setSignIn(true);
    setResetPassword(false);
    setIsModalVisible(!isModalVisible);
  };

  useEffect(() => {
    if (forgotPasswordToken) {
      setIsModalVisible(true);
    }
  }, [])

  return (
    <>
    <CModal visible={confirmLogout} onClose={() => setConfirmLogout(false)}>
      <CModalHeader onClose={() => setConfirmLogout(false)}>
        <CModalTitle>Sunteți sigur(ă) ?!</CModalTitle>
      </CModalHeader>
      {
        <CModalBody>Urmează să vă delogați. Sunteți sigur(ă) ?</CModalBody>
      }
      <CModalFooter>
        <button onClick={() => {setConfirmLogout(false)}} className="btn btn-secondary">
          Nu.
        </button>
        <button onClick={() => {logout()}} className="btn btn-ok">
            Da, sunt sigur(ă).
        </button>
      </CModalFooter>
    </CModal>

    <CModal visible={isModalVisible} onClose={() => toggleModal()}>
      {/* Add your modal content here */}
      <CModalHeader closeButton>
        <CModalTitle>{signIn ? "Conectare" : "Creare cont" }</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <Login someProp = {signIn} resetPassword={resetPassword} forgotPasswordToken = {forgotPasswordToken} closeModal={() => toggleModal()}/>
        <CModalFooter>
        {!resetPassword && !forgotPasswordToken ? 
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <div style={{marginRight: '10px'}}>{signIn ? 'Nu ai cont? ' : 'Ai deja cont? '} </div>
              <button className='btn-ok' onClick={() => setSignIn(!signIn)} style={{ background: '#163020' }}>{signIn ? 'Creează unul acum' : 'Autentifică-te.'}</button>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '10px'
            }}>
            {signIn && !forgotPasswordToken ? 
              <> 
                <div style={{marginRight: '10px'}} >Ai uitat parola?</div>
                <button className='btn-ok' onClick={() => setResetPassword(!resetPassword)} style={{ background: '#163020' }}>Resetare parolă</button> 
              </> : 
              <></> 
            }
            </div>
          </div>
        : !forgotPasswordToken ?  <button className='btn-ok' onClick={() => setResetPassword(!resetPassword)} style={{ background: '#163020' }}>Înapoi spre autentificare</button> : <></> }
        </CModalFooter>
      </CModalBody>
    </CModal>
    <nav>
      <Link to="/" className="title">
        Acasă
      </Link>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""} onClick={() => {menuOpen ? setMenuOpen(!menuOpen) : setMenuOpen(menuOpen)}}>
        <li>
          <NavLink to="/exemplare">Exemplare</NavLink>
        </li>
        <li>
          <NavLink to="/intrebari">Mediu de învățare</NavLink>
        </li>
        <li>
          <NavLink to="/test">Test Oficial ( 30 întrebări )</NavLink>
        </li>
        {authToken ? (<>
          <li>
            <NavLink to="/profil">
              Profil
            </NavLink>
          </li>
          <li onClick={() => setConfirmLogout(true)}>
            <a>Deconectare</a>
          </li>
          </>) 
          : (
              <>
                <li onClick={toggleModalSignIn}>
                  <a>Conectare</a>
                </li>
                <li onClick={() => {setSignIn(false); setResetPassword(false); setIsModalVisible(true)}}>
                  <a>Creare cont</a>
                </li>
              </>
            )}
      </ul>
    </nav>
    </>
  );
};