import React from 'react'

const Contact = React.lazy(() => import('./components/pages/Contact'))
const Home = React.lazy(() => import('./components/pages/Home'))
const Intrebari = React.lazy(() => import('./components/pages/Intrebari'))
const Test = React.lazy(() => import('./components/pages/Test'))


const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/contact', name: 'Competitions', element: Contact },
  { path: '/intrebari', name: 'Submissions', element: Intrebari },
  { path: '/intrebari', name: 'Submissions', element: Test },
]

export default routes
